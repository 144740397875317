<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Stock par camion</h4>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Affectation d'un stock à un camion"
          :modal="true"
          class="p-fluid"
        >
        <div v-if="loadingadd" class="card">
            Loading...
        </div>
          <div v-else class="card" >
            
            <div class="p-fluid formgrid grid" v-if="!editing" >
              <div class="field col-12 md:col-12">
                <Dropdown v-model="selectedUser" :options="users" filter optionLabel="nom" placeholder="Sélectionner un camion" >
                  <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                          <div>{{ slotProps.value.nom }}</div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps">
                      <div class="flex align-items-center">
                          <div>{{ slotProps.option.nom }}</div>
                      </div>
                  </template>
              </Dropdown>
              </div>
            </div>
            <div class="p-fluid formgrid grid" v-else >
              <div class="field col-12 md:col-12">
                <h3>Camion: {{ userToUpdate.nom}}</h3>
              </div>
            </div>
            <div class="p-fluid formgrid grid" v-if="selectedUser != null && selectedUser.id == 0">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Non </label>

                <InputText
                  v-model="clientToAdd.nom"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nom'] }"
                />
                <small class="p-error" v-if="errorsAdd['nom']">
                  {{ errorsAdd["nom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Prénom </label>
                <InputText
                  v-model="clientToAdd.prenom"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['prenom'] }"
                />
                <small class="p-error" v-if="errorsAdd['prenom']">
                  {{ errorsAdd["prenom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="lastname2">Téléphone </label>
                <InputText
                  v-model="clientToAdd.tele"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['tele'] }"
                />
                <small class="p-error" v-if="errorsAdd['tele']">
                  {{ errorsAdd["tele"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="zip">GSM</label>
                <InputText
                  v-model="clientToAdd.gsm"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['gsm'] }"
                />
                <small class="p-error" v-if="errorsAdd['gsm']">
                  {{ errorsAdd["gsm"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Plafond </label>
                <InputNumber
                  v-model="clientToAdd.plafondUser"
                  mode="currency"
                  currency="MAD"
                  :class="{ 'is-invalid': errorsAdd['plafondUser'] }"
                />
                <small class="p-error" v-if="errorsAdd['plafondUser']">
                  {{ errorsAdd["plafondUser"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Email </label>
                <InputText
                  v-model="clientToAdd.email"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['email'] }"
                />
                <small class="p-error" v-if="errorsAdd['email']">
                  {{ errorsAdd["email"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Mot de passe </label>
                <InputText
                  v-model="clientToAdd.password"
                  type="password"
                  :class="{ 'is-invalid': errorsAdd['password'] }"
                />
                <small class="p-error" v-if="errorsAdd['password']">
                  {{ errorsAdd["password"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Confirmation</label>
                <InputText
                  v-model="confirmpassword"
                  type="password"
                  :class="{ 'is-invalid': errorsAdd['confirmpassword'] }"
                />
                <small class="p-error" v-if="errorsAdd['confirmpassword']">
                  {{ errorsAdd["confirmpassword"] }}
                </small>
              </div>
            </div>
            <h4>Produits</h4>
            <div class="p-fluid formgrid grid">
              
              <div class="field col-12 md:col-3">
                <label for="city">categorie</label>
                <Dropdown
                  v-model="categorie"
                  :options="sortOptions"
                  optionLabel="nomCategorie"
                  placeholder="Trier par catégorie"
                  @change="onSortChange()"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Produit</label>
                <Dropdown
                  v-model="produit"
                  :options="dataviewValue"
                  optionLabel="nomProduit"
                  placeholder="produit"
                />
              </div>
              <div class="field col-12 md:col-2">
                <label for="city">Quantité initial</label>
                <InputNumber v-model="qty" inputId="minmax-buttons"  showButtons :min="0"/>
              </div>
              <div class="field col-12 md:col-2">
                <label for="city">Charge fix</label>
                <InputNumber v-model="stock" inputId="minmax-buttons"  showButtons :min="0"/>
              </div>
              <div class="field col-12 md:col-1">
                <label style="color: rgb(255, 255, 255);" for="city" >Ajouter</label>
                <Button icon="pi pi-plus"  style="background-color: green;" severity="success" @click="addProductToArr()" />
              </div>
            </div>
            <InlineMessage severity="error" v-if="messageErr!=''">{{ messageErr }}</InlineMessage >
            <DataTable v-if="produitsArray.length > 0" :value="produitsArray"  responsiveLayout="scroll">
              
              <Column field="name" header="Produit" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  {{slotProps.data.nomProduit}}
                </template>
              </Column>
              <Column field="qty" header="Quantité initial" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span v-if="slotProps.data.activitedr">
                    <InputNumber style="width: 50%;" v-model="slotProps.data.qty"  :min="0"/>
                    <!-- <Button
                    title="Modifier"
                    style="margin-left: 6px"
                    icon="pi pi-check"
                    class="p-button-raised p-button-rounded p-button-success"
                    @click="slotProps.data.activitedr=true" /> -->
                  </span>
                  <span v-else>
                    {{slotProps.data.qty}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="stock" header="Charge fix" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span v-if="slotProps.data.activitedr">
                    <InputNumber style="width: 50%;" v-model="slotProps.data.stock"  :min="1"/>
                    <Button
                    title="Modifier"
                    style="margin-left: 6px"
                    icon="pi pi-check"
                    class="p-button-raised p-button-rounded p-button-success"
                    @click="slotProps.data.activitedr=false" />
                  </span>
                  <span v-else>
                    {{slotProps.data.stock}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="action" header="Actions" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span class="">
                    
                  <Button
                  title="Modifier"
                  style="margin-right: 6px"
                  icon="pi pi-cog"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="slotProps.data.activitedr=true" />
                  <Button
                  style="margin-right: 6px"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  title="Retour de la commande entière"
                  @click="deleteProdFromArr(slotProps.data.id)" />
                </span>
                </template>
              </Column>
            </DataTable>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
            
              @click="dialogOfAdd = false;editing=false;"
            />
            <Button
           
              label="Ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="addCamionSettings()"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfCharge"
          :style="{ width: '900px' }"
          header="Charger le stock à un camion"
          :modal="true"
          class="p-fluid"
        >
          <div class="card" >
            <h3>Camion: {{camiontocharge.nom}}</h3>
            <Message v-if="chrgeError!=null" :closable="false" severity="error" sticky>{{chrgeError}}</Message>
            <DataTable v-if="produitsArraycharge.length > 0" :value="produitsArraycharge"  responsiveLayout="scroll">
              
              <Column field="nomProduit" header="Produit" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  {{slotProps.data.nomProduit}}
                </template>
              </Column>
              <Column field="stock" header="Stock" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span>
                    {{slotProps.data.stock}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="charge_fix" header="Charge fix" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span >
                    {{slotProps.data.charge_fix}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="qtyToAdd" header="Quantité à ajouter" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span v-if="slotProps.data.activitedr">
                    <InputNumber style="width: 50%;" v-model="slotProps.data.qtyToAdd"  :min="0"/>
                    <Button
                    title="Modifier"
                    style="margin-left: 6px"
                    icon="pi pi-check"
                    class="p-button-raised p-button-rounded p-button-success"
                    @click="slotProps.data.activitedr=false" />
                  </span>
                  <span v-else>
                    {{slotProps.data.qtyToAdd}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="action" header="Actions" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span class="">
                    
                  <Button
                  title="Modifier"
                  style="margin-right: 6px"
                  icon="pi pi-cog"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="slotProps.data.activitedr=true" />
                </span>
                </template>
              </Column>
            </DataTable>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
            
              @click="dialogOfCharge = false"
            />
            <Button
           
              label="Ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="changeValCharge()"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfDailyStock"
          :style="{ width: '900px' }"
          header="Stock journalier"
          :modal="true"
          class="p-fluid"
        >
          <div v-if="loadingDialog" class="card">
            Loading...
          </div>
          <div v-else class="card" >
            <h3 v-if="camiontocharge" >Camion: {{camiontocharge.nom}}</h3>
            <div class="p-fluid formgrid grid">
              
              <div class="field col-12 md:col-4">
                <label for="city">Date</label>
                <Calendar v-model="camionToChek.date" />
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Camion</label>
                <Dropdown
                  v-model="camionToChek.camion_id"
                  :options="camions"
                  optionLabel="user.nom"
                  placeholder="produit"
                />
              </div>
              
              <div class="field col-12 md:col-4">
                <label style="color: rgb(255, 255, 255);" for="city" >Actualiser</label>
                <Button severity="success" label="Actualiser" @click="changecam()" />
              </div>
            </div>
            <DataTable v-if="dailytransactions.length > 0" :value="dailytransactions"  responsiveLayout="scroll">
              
              <Column field="product_name" header="Produit" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  {{slotProps.data.product_name}}
                </template>
              </Column>
              <!-- <Column field="charge_fix" header="Stock" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span>
                    {{slotProps.data.charge_fix-slotProps.data.delivered_quantity+slotProps.data.extra_quantity}}

                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column> -->
              <Column field="delivered_quantity" header="Quantité livrée" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span >
                    {{slotProps.data.delivered_quantity}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="added_quantity" header="Quantité ajoutée" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  
                  <span >
                    {{slotProps.data.added_quantity}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                  
                 
                </template>
              </Column>
              <Column field="extra_quantity" header="quantité supplémentaire ajoutée" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span >
                    {{slotProps.data.extra_quantity}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                </template>
              </Column>
              <!-- <Column field="inventaire initial" header="Stock restant" :sortable="true" headerStyle="min-width:8rem;">
                <template #body="slotProps">
                  <span >
                    {{parseFloat(slotProps.data.dailyinitial_quantity )+ parseFloat(slotProps.data.extra_quantity) + parseFloat(slotProps.data.added_quantity) - parseFloat(slotProps.data.delivered_quantity)}}
                    <span v-if="slotProps.data.unite  != '' || slotProps.data.unite  != null ">{{slotProps.data.unite }}</span>
                  </span>
                </template> 
              </Column> -->
            </DataTable>
          </div>
          <template #footer>
            <Button
              label="Fermer"
              icon="pi pi-times"
              class="p-button-text"
            
              @click="dialogOfDailyStock = false"
            />
            <!-- <Button
           
              label="Ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="changeValCharge()"
            /> -->
          </template>
        </Dialog>
        <Toolbar class="mb-4" >
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un nouveau Camion"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew()"
              />
            </div>
          </template>
          <!-- <template v-slot:end>
            <Button  label="Importer " icon="pi pi-plus" chooselabel="Importer " @click="showImport()" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV()"  />
					</template> -->
        </Toolbar>
        <DataTable
          :value="camions"
          v-model:expandedRows="expandedRows"
          
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          responsiveLayout="scroll"
          :loading="loading1"
          :filters="filters1"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          :rowHover="true"
          :globalFilterFields="[
            'nom',
            'prenom',
            'tele',
          ]"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun commande trouvé. </template>
          <template #loading>
            Chargement des données commandes. Veuillez patienter.
          </template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="nom" header="Nom" sortable>
            <template #body="slotProps">
              {{ slotProps.data.user.nom}}
            </template>
          </Column>

          <Column field="prenom" header="Prénom" sortable>
            <template #body="slotProps">
              {{ slotProps.data.user.prenom }}
            </template>
          </Column>
          <Column field="tele" header="Téléphone" sortable>
            <template #body="slotProps">
              {{ slotProps.data.user.tele }}
            </template>
          </Column>
          <Column header="Actions">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button
                  title="charger camion"
                  label="Charger camion"
                  style="margin-right: 6px"
                  icon="pi pi-cog"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="chargerCamion(data.data)"
                   />
                   <Button
                  title="Stock journalier"
                  label="Stock journalier"
                  style="margin-right: 6px"
                  icon="pi pi-cog"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="showDailyStock(data.data)"
                   />
                <Button
                  title="Modifier la commande"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="editCamionSettings(data.data)"
                />
                
               
                

                <Button
                  title="Supprimer commande"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteSettings(data.data)"
                />
              </span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="orders-subtable">
              <h5>Produits</h5>
              <DataTable
                :value="slotProps.data.products"
                responsiveLayout="scroll"
              >
                <Column field="nomProduit" header="Produit" sortable></Column>
               
                <Column field="charge_fix" header="La charge fix" sortable>
                  <template #body="{ data }">
                    {{ data.charge_fix }}  {{ data.unite }}
                  </template>
                </Column>
                <Column field="stock" header="Stock" sortable>
                  <template #body="{ data }">
                    {{ data.stock }}  {{ data.unite }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
        <!-- <DataTable
          :value="produits"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="['nomProduit', 'numSerie', 'stock', 'full_name']"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun client trouvé. </template>
          <template #loading>
            Chargement des données produits. Veuillez patienter.
          </template>
          <Column
            field="nomProduit"
            header="Produit"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nomProduit }}
            </template>
          </Column>
          <Column
            field="numSerie"
            header="série"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.numSerie }}
            </template>
          </Column>
          <Column
            field="stock"
            header="Stock"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.stock }}
            </template>
          </Column>
          <Column
            field="full_name"
            header="Camion"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.full_name }}
            </template>
          </Column>

          <Column header="Image">
            <template #body="slotProps">
              <img
                :src="
                  url +
                  slotProps.data.imageProduit
                "
                class="shadow-2"
                width="60"
              />
            </template>
          </Column>

         
        </DataTable> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      loadingDialog: false,
      loadingadd: false,
      url:null,
      Permissions: { update: false, delete: false, add: false, show: false },
      messageErr: "",
      filters1: null,
      loading1: true,
      dialogOfAdd: false,
      dialogOfCharge: false,
      dialogOfDailyStock: false,
      editing: false,
      userToUpdate:null,
      clientToAdd: {
        role_id: 3,
        prenom: "",
        nom: "",
        tele: "",
        gsm: "",
        email: "",
        password: "",
        plafondUser: null,
      },
      confirmpassword: "",
      errorsAdd: [],
      users: [],
      produits: [],
      camions: [],
      dataviewValue: [],
      sortOptions: [],
      categorie: null,
      search: null,
      produit: null,
      qty: 1,
      stock: 1,
      produitsArray: [],
      produitsArraycharge: [],
      camiontocharge: null,
      selectedUser:null,
      camion_id:null,
      expandedRows: [],
      chrgeError:null,
      dailytransactions: [],
      camionToChek: {
        date:null,
        camion_id:null,
      },
    };
  },

  mounted() {
    this.url = this.$Gurl;
    // this.Permissions.update =
    //   localStorage.permissionNames.includes("Liste stock camion");
    // this.Permissions.delete =
    //   localStorage.permissionNames.includes("Liste stock camion");
    // this.Permissions.add =
    //   localStorage.permissionNames.includes("Liste stock camion");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste stock camion");
    if (this.Permissions.show) {
      // this.showAllproduits();
      this.getProduits();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    // if (localStorage.token == "" || localStorage.token == null) {
    //   this.$router.push("/login");
    // }
    this.initFilters1();
  },
  methods: {
    changecam(){
      this.camiontocharge=this.camionToChek.camion_id;
      console.log("this.camiontocharge",this.camiontocharge.user);
      const formattedDate = moment(String(this.camionToChek.date)).format('YYYY-MM-DD')  // Format as YYYY-MM-DD
      console.log(formattedDate);
      axios
        .get('/dailytransactions', {
          params: { specific_day: formattedDate, camion_id: this.camiontocharge.user.id },
        })
        .then((response) => {
          console.log(response);
          this.dailytransactions=[];
          this.dailytransactions=response.data.quantities;
          console.log(response.data.quantities);
        })
        .catch((error) => {
          console.error('Error fetching quantities:', error);
        });
    },
    showDailyStock(data){
      this.camiontocharge=data.user;
      this.camionToChek.camion_id=data;
      console.log(data.user);
      this.dialogOfDailyStock=true;
      this.loadingDialog=true;
      const currentDate = new Date();
      this.camionToChek.date=currentDate;
      const formattedDate = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      console.log(formattedDate);
      axios
        .get('/dailytransactions', {
          params: { specific_day: formattedDate, camion_id: data.user.id },
        })
        .then((response) => {
          console.log(response);
          this.dailytransactions=response.data.quantities;
          this.loadingDialog=false;
        })
        .catch((error) => {
          console.error('Error fetching quantities:', error);
        });
    },
    changeValCharge(){
      this.chrgeError=null;
      const updateData = [];
      for (let i = 0; i < this.produitsArraycharge.length; i++) {
        updateData.push({
          id: this.produitsArraycharge[i].camion_setting_id,
          stock: this.produitsArraycharge[i].stock+this.produitsArraycharge[i].qtyToAdd,
          produit_id: this.produitsArraycharge[i].produit_id,
          qtt_ajoute: this.produitsArraycharge[i].qtyToAdd,
          camion_id: this.camiontocharge.id,
          dailyinitial_quantity: this.produitsArraycharge[i].stock,
          extra_quantity: this.produitsArraycharge[i].stock+this.produitsArraycharge[i].qtyToAdd-this.produitsArraycharge[i].charge_fix,
        });
      }
  console.log(updateData);
      axios
        .post('addStock', updateData)
        .then((response) => {
          // Handle success
          if (response.data.status=="error") {
            this.chrgeError=response.data.message;
          }else{
            this.chrgeError=null;
            this.camiontocharge=null;
            this.produitsArraycharge=[];
            this.dialogOfCharge=null;
            this.$swal({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 2000,
              });
              this.getProduits();
          }
          console.log(response);
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating products", error);
        });
    },
    chargerCamion(data){
      this.chrgeError=null;
      this.camiontocharge=data.user;
      this.produitsArraycharge=[];
      var diff=0;
      for (let index = 0; index < data.products.length; index++) {
        diff=data.products[index].charge_fix-data.products[index].stock;
        if (diff<0) {
          diff=0;
        }
        this.produitsArraycharge.unshift({"camion_setting_id":data.products[index].camion_setting_id,"charge_fix":data.products[index].charge_fix,"nomProduit":data.products[index].nomProduit,"produit_id":data.products[index].produit_id,"stock":data.products[index].stock,"unite":data.products[index].unite,"qtyToAdd":diff,"activitedr":false});
      }
      
      console.log(data.products);
      this.dialogOfCharge=true;
    },
    deleteSettings(item) {
      
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("/camion-settings/camion/" + item.user.id)
            .then((response) => {
              console.log(response);
              var index = this.camions.map((x) => {return x.user.id;}).indexOf(item.user.id);
              this.camions.splice(index, 1);
              this.$swal({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    UpsateCamionSettings(){
      let products=[];
      for (let index = 0; index < this.produitsArray.length; index++) {
        products.push({stock:this.produitsArray[index].qty,produit_id:this.produitsArray[index].id,charge_fix:this.produitsArray[index].stock}) ;
      }
      const camionSetting = {
        camion_id:this.userToUpdate.id,
        products:products
      };
      console.log(camionSetting)
      axios
        .post("camion-settings/"+this.userToUpdate.id, camionSetting)
        .then((response) => {
          this.loading1 = false;
          console.log(response);
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.getProduits();
          this.dialogOfAdd = false;
          this.editing=false;
          
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        
    },
    editCamionSettings(item){
      this.initform();
      console.log(item)
      this.editing=true;
      this.produitsArray=[];
      for (let index = 0; index < item.products.length; index++) {
        this.produitsArray.unshift({"nomProduit":item.products[index].nomProduit,"id":item.products[index].produit_id,"unite":item.products[index].unite,"qty":item.products[index].stock,"stock":item.products[index].charge_fix,"activitedr":false});
      }
      this.userToUpdate=item.user;
			this.product = {};
			this.submitted = false;
      this.dialogOfAdd=true;
      
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Product Expanded",
        detail: event.data.name,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Product Collapsed",
        detail: event.data.name,
        life: 3000,
      });
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      this.$toast.add({
        severity: "success",
        summary: "All Rows Expanded",
        life: 3000,
      });
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({
        severity: "success",
        summary: "All Rows Collapsed",
        life: 3000,
      });
    },
    initform(){
      this.selectedUser=null,
      this.qty= 1;
      this.stock= 1;
      this.produitsArray= [];
      this.selectedUser=null;
      this.camion_id=null;
      this.clientToAdd= {
        role_id: 3,
        prenom: "",
        nom: "",
        tele: "",
        gsm: "",
        email: "",
        password: "",
        plafondUser: null,
      },
      this.confirmpassword = ""; 
      this.errorsAdd = [];
      this.messageErr=""
  },
    pushCamionSettings(){
      let products=[];
      for (let index = 0; index < this.produitsArray.length; index++) {
        products.push({stock:this.produitsArray[index].qty,produit_id:this.produitsArray[index].id,charge_fix:this.produitsArray[index].stock}) ;
      }
      const camionSetting = {
        camion_id:this.camion_id,
        products:products
      };
      axios
        .post("camion-settings", camionSetting)
        .then((response) => {
          console.log(response);
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.getProduits();
          this.dialogOfAdd = false;
          this.editing=false;
          
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        
    },
    addCamionSettings(){
      this.messageErr="";
      if (this.editing) {
        if (this.produitsArray.length==0) {
        this.messageErr="Veuillez ajouter les produits !"
      }else{
        
        this.UpsateCamionSettings()
      }
      }else{
        if (this.selectedUser == null) {
        this.messageErr="Veuillez choisir un camion !"
      }
      else if (this.selectedUser.id==0) {
        this.checkFormAdd();
      }else if (this.selectedUser.id > 0) {
        if (this.produitsArray.length==0) {
        this.messageErr="Veuillez ajouter les produits !"
        
        
      }else{
        this.camion_id=this.selectedUser.id;
        this.pushCamionSettings()
      }
      }
      }
     
    },
    addUser(){
      axios
        .post("registerUser", this.clientToAdd)
        .then((response) => {
          console.log(response);
          axios
        .get("showUsers")
        .then((res) => {
          console.log(res);
          this.users = res.data.users;
          this.users = this.users.filter(function(value) {
          return value.role_id == 3; });
          this.users.push({nom:"nouveaux camion",id:0});
          this.camion_id = this.users.find(x => x.email === this.clientToAdd.email).id;
          this.pushCamionSettings();
        });

        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    checkFormAdd() {
      
      let checked = true;
      this.errorsAdd = [];
      
      if (this.clientToAdd.nom == "") {
        this.errorsAdd["nom"] = "le champ Nom est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.plafondUser == "") {
        this.clientToAdd.plafondUser = null;
      }
      if (this.clientToAdd.prenom == "") {
        this.errorsAdd["prenom"] = "le champ Prénom est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.tele == "") {
        this.errorsAdd["tele"] = "le champ Téléphone est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.gsm == "") {
        this.errorsAdd["gsm"] = "le champ GSM est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.role_id == 0) {
        this.errorsAdd["role_id"] = "le champ role est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.email == "") {
        this.errorsAdd["email"] = "le champ Email est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.password == "") {
        this.errorsAdd["password"] = "le champ mot de passe est obligatoire.";
        checked = false;
      } else if (this.clientToAdd.password.length < 6) {
        this.errorsAdd["password"] =
          "le champ mot de passe doit comporter 6 caractères au minimum.";
        checked = false;
      } else if (this.confirmpassword == "") {
        this.errorsAdd["confirmpassword"] =
          "merci de confirmer votre mot de passe.";
        checked = false;
      } else if (this.confirmpassword != this.clientToAdd.password) {
        this.errorsAdd["confirmpassword"] = "confirmation erroné.";
        checked = false;
      }
      if (this.produitsArray.length==0) {
        this.messageErr="please select the products !"
      }

      if (checked) {
        console.log(this.errorsAdd);
        this.addUser();
      }
    },
    deleteProdFromArr(id){
      let index=this.produitsArray.findIndex(x => x.id === id);
      if (index != -1) {
        this.produitsArray.splice(index,1)
      }
    },
    addProductToArr(){
      let index=this.produitsArray.findIndex(x => x.id === this.produit.id);
      this.messageErr="";
      if (this.qty < 1) {
        this.messageErr="insérer une quantité valide";
      }
      if (this.stock < 1) {
        this.messageErr="insérer un Charge fix valide";
      }
      if (index != -1) {
      
        console.log("rejo3 lah aaa cherif")
        this.messageErr="produit déjà inséré";
      }else{
        this.produitsArray.unshift({"nomProduit":this.produit.nomProduit,"id":this.produit.id,"unite":this.produit.unite,"qty":this.qty,"stock":this.stock,"activitedr":false});
        console.log(this.produitsArray);
      }
    },
    onSortChange() {
      this.search = "";
      if (this.categorie.id == 0) {
        this.dataviewValue = this.produits;
      } else {
        this.dataviewValue = this.produits.filter((obj) => {
          return obj.categorie_id === this.categorie.id;
        });
      }
      this.produit=this.dataviewValue[0];
    },
    getProduits() {
      axios
        .get("camion-settings")
        .then((response) => {
          console.log(response);
          if (response.data.status == "error") {
            this.$swal("Erreur ", response.data.message);
          } else {
            this.dataviewValue = response.data.produit;
            this.produits = response.data.produit;
            this.camions = response.data.groupedSettings;
            

            this.sortOptions = response.data.categories;
            this.sortOptions.unshift({ id: 0, nomCategorie: "tout" });
            this.categorie = this.sortOptions[0];
            this.produit=this.dataviewValue[0];

            this.loading1 = false;
          }
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    openNew() {
      this.dialogOfAdd = true;
      this.loadingadd=true;
      this.initform();
			// this.product = {};
			// this.submitted = false;
      axios
        .get("formAddsetting")
        .then((response) => {
          console.log(response);
          this.users = response.data.users;
          console.log(this.users);
          this.users = this.users.filter(function(value) {
            return value.role_id == 3; });
            this.users.push({nom:"nouveaux camion",id:0});
            console.log(this.users);
          this.loading1 = false;
          
          this.editing=false;
          this.loadingadd=false;
        });
			
		},
    showAllproduits() {
      axios
        .get("liststockCamion")
        .then((response) => {
          this.produits = response.data.camionStock;
          console.log(response);
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    calculateCustomerTotal(name) {
      let total = 0;
      if (this.customer3) {
        for (let customer of this.customer3) {
          if (customer.representative.name === name) {
            total++;
          }
        }
      }

      return total;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>